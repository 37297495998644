/* eslint-disable @typescript-eslint/naming-convention */
declare global {
	interface Window {
		Shopify: {
			analytics: {
				publish: (event: string, data: Record<string, unknown>) => void
			}
		}
		dataLayer: unknown[]
	}
}

interface GTMEventData {
	items?: unknown[]
	item_list_name?: string
	clic_cta?: string
	href?: string
	menu_element?: string
}

export function init() {
	const ctas = document.querySelectorAll('a[data-tag],button[data-tag],a[data-item]')
	ctas.forEach(cta => {
		cta.addEventListener('click', e => {
			const target = e.currentTarget as HTMLElement
			const item = target.dataset.item

			const o: GTMEventData = {}
			let event = 'cta_clicked'

			if (item) {
				o.items = [JSON.parse(item)]
				if (target.dataset.list) o.item_list_name = target.dataset.list
				event = 'item_selected'
			} else {
				o.clic_cta = target.dataset.tag
				if ('href' in target && typeof target.href === 'string') o.href = target.href

				const menu_element = target.dataset.menu
				if (menu_element) o.menu_element = menu_element

				event = 'item_selected'
			}

			window.Shopify.analytics.publish(event, o as Record<string, unknown>)
		})
	})
}
