import { devices } from '@/utils/devices'

export default {
	name: 'global',
	store() {
		return {
			isMinicartVisible: false,
			isSearchVisible: false,
			device: devices[0].name as 'mobile' | 'desktop' | 'tablet',
			hasBodyLock: false,
			bodyLocksCount: 0,
			savedScrollY: window.scrollY,
			prefersReducedMotion: false,
			cart: undefined,
			init() {
				this.initLiquidAJaxCart()
				this.setDevice()
				window.addEventListener('resize', this.setDevice.bind(this), { passive: true })

				this.initPrefersReducedMotion()
			},
			initLiquidAJaxCart() {
				// @ts-expect-error Liquid Ajax Cart is not typed
				window.liquidAjaxCart.conf('updateOnWindowFocus', import.meta.env.PROD)

				document.addEventListener('liquid-ajax-cart:request-end', (event: any) => {
					const { requestState, cart, previousCart } = event.detail

					if (requestState.responseData?.ok && cart.item_count > previousCart.item_count) {
						this.isMinicartVisible = true
					}

					this.cart = cart
				})

				document.addEventListener('minicart:refresh', () => {
					// @ts-expect-error Liquid Ajax Cart is not typed
					window.liquidAjaxCart.update()
				})
			},
			setDevice() {
				const vw = window.innerWidth
				const currentDevice = devices.find(d => d.breakpoint < vw)?.name || devices[0].name
				this.device = currentDevice
			},
			bodyScrollLock(shouldLock) {
				if (shouldLock) {
					if (!this.hasBodyLock) {
						this.savedScrollY = window._lenis.scroll
						window._lenis.destroy()

						document.body.style.position = 'fixed'
						document.body.style.top = `-${this.savedScrollY}px`
					}
					this.bodyLocksCount += 1
					this.hasBodyLock = shouldLock
				} else {
					this.bodyLocksCount -= 1

					if (this.hasBodyLock && this.bodyLocksCount === 0) {
						document.body.style.removeProperty('position')
						document.body.style.removeProperty('top')

						window._getLenis()
						window._lenis.scrollTo(this.savedScrollY, { immediate: true, force: true })

						this.hasBodyLock = shouldLock
					}
				}
			},
			initPrefersReducedMotion() {
				const mql = window.matchMedia('(prefers-reduced-motion: reduce)')
				mql.addEventListener('change', this.handlePrefersReducedMotionChange.bind(this), { passive: true })
				this.prefersReducedMotion = mql.matches
			},
			handlePrefersReducedMotionChange(event: MediaQueryListEvent) {
				this.prefersReducedMotion = event.matches
			}
		}
	}
}
