/* eslint-disable @typescript-eslint/naming-convention */
interface CookieFirstConsent {
	necessary: boolean
	functional: boolean
	performance: boolean
	advertising: boolean
}

export default {
	name: 'gpdr',
	component(props: { preconsent: any; cookiesUrl: string }) {
		const { preconsent, cookiesUrl } = props
		const form = this.$el.querySelector('form')
		const inputs = form.querySelectorAll('input[type="checkbox"]')

		return {
			cookies: [],
			tabIndex: 0,
			isLoading: false,
			shouldShow: false,

			updateConsent(consent: CookieFirstConsent) {
				const shopifyConsent = {
					preferences: consent.functional,
					analytics: consent.performance,
					marketing: consent.advertising,
					sale_of_data: consent.advertising
				}

				if (window.Shopify?.customerPrivacy) {
					window.Shopify.customerPrivacy.setTrackingConsent(shopifyConsent)
				}

				// Dispatch custom Shopify pixel event
				if (window.dataLayer) {
					window.dataLayer.push({
						event: 'consentChange',
						...consent
					})
				}

				// Close slideout
				this.$store.gpdr.isSlideoutOpened = false

				// Close GPDR banner
				this.shouldShow = false

				return consent
			},

			async loadCookies() {
				if (this.cookies.length === 0) {
					this.isLoading = true
					try {
						const response = await fetch(cookiesUrl)
						if (!response.ok) {
							throw new Error('Failed to fetch cookies data')
						}
						this.cookies = (await response.json()).cookies
					} catch (error) {
						console.error('Error loading cookies:', error)
						this.cookies = []
					} finally {
						this.isLoading = false
					}
				}
			},

			submitConsent(e: Event) {
				const form = (e.target as HTMLElement).closest('form')
				if (!form) return

				const inputs = form.querySelectorAll('input[type="checkbox"]')
				const consent = {
					necessary: true,
					functional: false,
					performance: false,
					advertising: false
				}
				inputs.forEach(cb => {
					consent[(cb as HTMLInputElement).name] = (cb as HTMLInputElement).checked
				})
				this.updateConsent(consent)
			},

			acceptAllConsent() {
				this.updateConsent({
					necessary: true,
					functional: true,
					performance: true,
					advertising: true
				})

				inputs.forEach(cb => {
					;(cb as HTMLInputElement).checked = true
				})
			},

			init() {
				// Initialize the global store
				if (!this.$store.gpdr) {
					this.$store.gpdr = {
						isSlideoutOpened: false
					}
				}

				// Load shopify consent api
				window.Shopify.loadFeatures(
					[
						{
							name: 'consent-tracking-api',
							version: '0.1'
						}
					],
					error => {
						if (!error) {
							// Restore shopify consents
							const rawConsents = window.Shopify.customerPrivacy.currentVisitorConsent()
							const consents = {
								necessary: true,
								performance: rawConsents.analytics === '' ? preconsent.performance : rawConsents.analytics === 'yes',
								functional: rawConsents.preferences === '' ? preconsent.functional : rawConsents.preferences === 'yes',
								advertising: rawConsents.marketing === '' ? preconsent.advertising : rawConsents.marketing === 'yes'
							}

							inputs.forEach(cb => {
								;(cb as HTMLInputElement).checked = consents[(cb as HTMLInputElement).name]
							})

							const hasEmptyConsent = Object.values(rawConsents).some(value => value === '')
							const shouldShowBanner = window.Shopify?.customerPrivacy?.shouldShowBanner()
							this.shouldShow = shouldShowBanner && hasEmptyConsent

							// Make sure to show / hide footer CTA based on eligibility
							this.$store.gpdr.shouldShowBanner = shouldShowBanner
						}
					}
				)

				// Watch slideout tabIndex
				this.$watch('tabIndex', (value: number) => {
					if (value === 1) {
						this.loadCookies()
					}
				})
			}
		}
	}
}
